import * as React from "react";
import { decimals, denomination } from "config";
import { useContext } from "context";
import denominate from "components/Denominate/formatters";
import StatCard from "components/StatCard";
import { Address, NetworkStake } from "@elrondnetwork/erdjs";
import { useState } from "react";

import SetPercentageFeeAction from "./SetPercentageFeeAction";
import UpdateDelegationCapAction from "./UpdateDelegationCapAction";
import AutomaticActivationAction from "./AutomaticActivationAction";
import ReDelegateCapActivationAction from "./ReDelegateCapActivationAction";
import pieDelegation from "assets/images/pie-delegation.svg";
import usersIcon from "assets/images/users-icon.svg";
import nodesIcon from "assets/images/nodes-icon.svg";
import aprIcon from "assets/images/apr-icon.svg";
import feeIcon from "assets/images/fee-icon.svg";
import autoActivation from "assets/images/auto-activation.svg";
import redelegCap from "assets/images/redelegation-cap.svg";

const Views = () => {
  const {
    dapp,
    egldLabel,
    totalActiveStake,
    numberOfActiveNodes,
    address,
    contractOverview,
    aprPercentage,
    numUsers,
  } = useContext();
  const [networkStake, setNetworkStake] = useState(new NetworkStake());

  const getPercentage = (amountOutOfTotal: string, total: string) => {
    let percentage =
      (parseInt(amountOutOfTotal.replace(/,/g, "")) /
        parseInt(total.replace(/,/g, ""))) *
      100;
    if (percentage < 1) {
      return "<1";
    }
    return percentage ? percentage.toFixed(2) : "...";
  };

  const isOwner = () => {
    let loginAddress = new Address(address).hex();
    return loginAddress.localeCompare(contractOverview.ownerAddress) === 0;
  };

  const isOwnerPath = () => {
    let currentURL = window.location.pathname;
    return currentURL.includes("owner") === true;
  };

  const getNetworkStake = () => {
    dapp.apiProvider
      .getNetworkStake()
      .then((value) => {
        setNetworkStake(value);
      })
      .catch((e) => {
        console.error("getTotalStake error ", e);
      });
  };

  React.useEffect(
    () => {
      getNetworkStake();
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <div className="cards d-flex flex-wrap ">
      <div className="row-cards">
        {isOwner() && isOwnerPath() ? (
          <StatCard
            image={pieDelegation}
            title="Delegation Cap"
            value={
              denominate({
                decimals,
                denomination,
                input: contractOverview.maxDelegationCap,
              }) || ""
            }
            valueUnit={egldLabel}
            color="green"
            percentage={`${getPercentage(
              denominate({
                input: totalActiveStake,
                denomination,
                decimals,
              }),
              denominate({
                decimals,
                denomination,
                input: contractOverview.maxDelegationCap,
              })
            )}% filled`}
          >
            <UpdateDelegationCapAction />
          </StatCard>
        ) : (
          denominate({
            decimals,
            denomination,
            input: contractOverview.maxDelegationCap,
          }) !== "0" &&
          denominate({
            decimals,
            denomination,
            input: contractOverview.maxDelegationCap,
          }) !== "" && (
            <StatCard
              image={pieDelegation}
              title="Delegation Cap"
              value={
                denominate({
                  decimals,
                  denomination,
                  input: contractOverview.maxDelegationCap,
                }) || ""
              }
              valueUnit={egldLabel}
              color="green"
              percentage={`${getPercentage(
                denominate({
                  input: totalActiveStake,
                  denomination,
                  decimals,
                }),
                denominate({
                  decimals,
                  denomination,
                  input: contractOverview.maxDelegationCap,
                })
              )}% filled`}
            ></StatCard>
          )
        )}
        {/* <StatCard
        title="Contract Stake"
        value={denominate({
          input: totalActiveStake,
          denomination,
          decimals,
        })}
        valueUnit={egldLabel}
        color="orange"
        percentage={`${getPercentage(
          denominate({
            input: totalActiveStake,
            denomination,
            decimals,
          }),
          denominate({
            input: networkStake.TotalStaked.toFixed(),
            denomination,
            decimals,
          })
        )}% of total stake`}
      /> */}
        <StatCard
          image={usersIcon}
          title="Number of Users"
          value={numUsers.toString()}
          color="orange"
          svg="user.svg"
        />
        <StatCard
          image={nodesIcon}
          title="Number of Nodes"
          value={numberOfActiveNodes}
          valueUnit=""
          color="purple"
          percentage={`${getPercentage(
            numberOfActiveNodes,
            networkStake.TotalValidators.toString()
          )}% of total nodes`}
        />
        <StatCard
          image={aprIcon}
          title="Computed APR"
          value={aprPercentage}
          valueUnit=""
          color="orange"
          percentage="Annual percentage rate incl. service fee"
          tooltipText="This is an approximate APR calculation for this year based on the current epoch"
        />
        <StatCard
          image={feeIcon}
          title="Service Fee"
          value={contractOverview.serviceFee || ""}
          valueUnit="%"
          color="red"
        >
          {isOwnerPath() && <SetPercentageFeeAction />}
        </StatCard>
      </div>
      <div className="admin-row">
        {isOwner() && isOwnerPath() && (
          <StatCard
            image={autoActivation}
            title="Automatic activation"
            value={
              contractOverview.automaticActivation === "true" ? "ON" : "OFF"
            }
            color="purple"
          >
            <AutomaticActivationAction
              automaticFlag={contractOverview.automaticActivation}
            />
          </StatCard>
        )}

        {isOwner() && isOwnerPath() && (
          <StatCard
            image={redelegCap}
            title="ReDelegate Cap"
            value={contractOverview.reDelegationCap === "true" ? "ON" : "OFF"}
            color="green"
            percentage="Cap for rewards"
            tooltipText="If your agency uses a max delegation cap and the ReDelegate Cap is OFF your delegators will be able to redelegate the reward to your agency. If the value is ON then the redelegate will not be accepted."
          >
            <ReDelegateCapActivationAction
              automaticFlag={contractOverview.reDelegationCap}
            />
          </StatCard>
        )}
      </div>
    </div>
  );
};

export default Views;
