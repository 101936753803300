import React, { useState, useEffect } from 'react';
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const getTotalActiveStake = async (): Promise<any> => {

    var totalActiveStake: any[] = [];
    var date = new Date();
    date.setMonth(date.getMonth() - 5);

    const result = await axios.get(
        `https://api-v2.egldscan.com/staking-providers/period?contract=erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqphllllsndz99p&dateStart=` + date.getTime() + `&dateEnd=` + Date.now()
    );

    let months: number[] = [];
    let k = 0;
    if (result) {
        Object.values(result.data).forEach(val => {
            // @ts-ignore
            let dateFormat = new Date(val.timestamp);
            let currentDate = new Date( Date.now());

            // @ts-ignore
            if(!months.includes(dateFormat.getMonth()) && dateFormat.getMonth() !== currentDate.getMonth()){
                months.push(dateFormat.getMonth());
                // @ts-ignore
                totalActiveStake.push(parseInt(val.totalActiveStake / 1000000000000000000));
            }
            k++;
            if(result.data.length === k){
                // add last value - the most recent
                // @ts-ignore
                totalActiveStake.push(parseInt(val.totalActiveStake / 1000000000000000000));
            }
        });

        return totalActiveStake;
    }

    return [];
};

const StakeGraph = () => {

    const [totalActiveStake, setTotalActiveStake] = useState([]);


    React.useEffect( () => {
        if(totalActiveStake.length === 0) {
            getTotalActiveStake().then((totalActiveStake) => setTotalActiveStake(totalActiveStake));
        }
    });
    let totalActiveStakeToday = parseInt(totalActiveStake[totalActiveStake.length-1]).toLocaleString();


    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July ',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const d = new Date();
    let month = months[d.getMonth()];

    let llabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];

    if(month === 'January') {
        llabels = ['Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'];
    }
    if(month === 'February') {
        llabels = [ 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'];
    }
    if(month === 'March') {
        llabels = ['Oct', 'Nov', 'Dec','Jan', 'Feb', 'Mar'];
    }
    if(month === 'April') {
        llabels = ['Nov', 'Dec','Jan', 'Feb', 'Mar', 'Apr'];
    }
    if(month === 'May') {
        llabels = ['Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'];
    }
    if(month === 'June') {
        llabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
    }
    if(month === 'July') {
        llabels = ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
    }
    if(month === 'August') {
        llabels = ['Mar', 'Apr', 'May', 'Jun', 'Jul ', 'Aug'];
    }
    if(month === 'September') {
        llabels = ['Apr', 'May', 'Jun', 'Jul ', 'Aug', 'Sep'];
    }
    if(month === 'October') {
        llabels = ['May', 'Jun', 'Jul ', 'Aug', 'Sep', 'Oct'];
    }
    if(month === 'November') {
        llabels = ['Jun', 'Jul ', 'Aug', 'Sep', 'Oct', 'Nov'];
    }
    if(month === 'December') {
        llabels = ['Jul ', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }


    const options: ApexOptions = {
        chart: {
            height: 330,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        grid: {
            show: true,
            borderColor: '#101010',
            row: {
                opacity: 1
            },
            column: {
                opacity: 0
            },
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
            padding: {
                top: 0,
                right: 15,
                bottom: 0,
                left: 30
            },
        },
        markers: {
            colors: ['#fdb25a', '#fdb25a', '#fdb25a']
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0,
                opacityFrom: 0.4,
                opacityTo: 0
            }
        },
        colors: ['#fdb25a', '#fdb25a', '#fdb25a'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            crosshairs: {
                show: false
            },
            labels: {
                style: {
                    colors: ['#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2'],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                }
            }
        },
        yaxis: {
            opposite: false,
            crosshairs: {
                show: false
            },
            labels: {
                style: {
                    colors: ['#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2', '#C2C2C2'],
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                }
            }
        },
        labels: llabels,
        tooltip: {
            theme: 'dark',
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return '<div class="arrow_box text-black-50">' +
                    '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
                    '</div>'
            }
        },

    };

    const series = [
        {
            data: totalActiveStake
        }
    ];
    return (
        <>
            <p>Contract stake</p>
            <h3>{totalActiveStakeToday} EGLD</h3>

            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={330}
                className={"margin-left-minus-14"}
            />
        </>
    );
};

export default StakeGraph;
