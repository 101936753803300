// @ts-ignore
import platform from "platform";
import React from "react";
import xPortalIcon from "assets/images/xportal-icon.png";

const WalletConnectLogin = () => {
  return (
    <a
      href={
        process.env.PUBLIC_URL +
        `/walletconnect?mobileplatform=${platform.os.family === "iOS" ||
          platform.os.family === "Android"}`
      }
      className="btn btn-primary px-sm-spacer mx-1 mx-sm-3"
    >
      <span className="icon-title">xPortal App</span>
      <img src={xPortalIcon} alt="xPortal Icon" />
      <span className="icon-subtitle">Mobile</span>
    </a>
  );
};

export default WalletConnectLogin;
