import React from "react";
import facebookIcon from "assets/images/facebook-icon.svg";
import twitterIcon from "assets/images/twitter-icon.svg";
import telegramIcon from "assets/images/telegram-icon.svg";
import logo from "assets/images/cslabs-logo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container menu-container footer ">
        <div className="row">
          <div className="col-md-6 text-md-left text-center">
            <a href="https://www.cslabs.io">
              <img className="img-fluid" src={logo} alt="Logo" />
            </a>
          </div>

          <div className="col-md-6 text-md-right text-center footer-social">
            <a href="https://twitter.com/ChainStateLabs">
              <img className="icon-footer" src={twitterIcon} alt="Twitter" />
            </a>
            <a href="cslabs.io">
              <img className="icon-footer" src={telegramIcon} alt="Telegram" />
            </a>
            <a href="cslabs.io">
              <img className="icon-footer" src={facebookIcon} alt="Facebook" />
            </a>
          </div>
        </div>

        <div className="row bottom-footer">
          <div className="col-md-4 text-md-left text-center order-2 order-md-1">
            <p>© 2023, Chain State Labs</p>
          </div>
          <div className="col-md-8 text-md-right text-center order-1 order-md-2">
            <ul className="navbar-nav">
              <li>
                <a href="https://www.cslabs.io/cookie-policy.html">
                  {" "}
                  Cookie Policy{" "}
                </a>
              </li>
              <li>
                <a href="https://www.cslabs.io/terms-conditions.html">
                  {" "}
                  Terms and Conditions{" "}
                </a>
              </li>
              <li>
                <a href="https://www.cslabs.io/privacy-policy.html">
                  {" "}
                  Privacy Policy{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
