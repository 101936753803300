import * as React from "react";
import { useContext, useDispatch } from "context";
import denominate from "components/Denominate/formatters";
import DelegateAction from "../Actions/DelegateAction";
import UndelegateAction from "../Actions/UndelegateAction";
import { contractViews } from "contracts/ContractViews";
import ClaimRewardsAction from "../Actions/ClaimRewardsAction";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import State from "components/State";
import { denomination, decimals, network, auctionContract } from "config";
import { decodeBigNumber, decodeUnsignedNumber } from "@elrondnetwork/erdjs";
import axios from "axios";
import StakeGraph from "../../../components/StakeGraph";
import lockIcon from "../../../assets/images/lock-icon.svg";
import trophyIcon from "../../../assets/images/trophy-icon.svg";

const MyDelegation = () => {
  const {
    dapp,
    address,
    egldLabel,
    delegationContract,
    loading,
  } = useContext();
  const dispatch = useDispatch();
  const { getClaimableRewards, getUserActiveStake } = contractViews;
  const [userActiveStake, setUserActiveStake] = React.useState("0");
  const [
    userActiveNominatedStake,
    setUserActiveNominatedStake,
  ] = React.useState("0");
  const [claimableRewards, setClaimableRewards] = React.useState("0");
  const [displayRewards, setDisplayRewards] = React.useState(false);
  const [displayUndelegate, setDisplayUndelegate] = React.useState(false);

  const getAllData = () => {
    dispatch({ type: "loading", loading: true });
    getClaimableRewards(dapp, address, delegationContract)
      .then((value) => {
        const untypedResponse = value.outputUntyped();
        if (
          untypedResponse.length > 0 &&
          decodeUnsignedNumber(untypedResponse[0]) !== 0
        ) {
          setDisplayRewards(true);
        }
        setClaimableRewards(
          denominate({
            denomination,
            decimals: 4,
            input: decodeBigNumber(untypedResponse[0]).toFixed(),
          }) || ""
        );
      })
      .catch((e) => console.error("getClaimableRewards error", e));
    getUserActiveStake(dapp, address, delegationContract)
      .then((value) => {
        const untypedResponse = value.outputUntyped();
        setUserActiveStake(
          denominate({
            denomination,
            decimals,
            input: decodeBigNumber(untypedResponse[0]).toFixed(),
          }) || ""
        );
        setUserActiveNominatedStake(
          decodeBigNumber(untypedResponse[0]).toFixed()
        );
        if (
          untypedResponse.length > 0 &&
          decodeUnsignedNumber(untypedResponse[0]) !== 0
        ) {
          setDisplayUndelegate(true);
        }

        dispatch({ type: "loading", loading: false });
      })
      .catch((e) => {
        console.error("getUserActiveStake error", e);
        dispatch({
          type: "loading",
          loading: false,
        });
      });
  };

  React.useEffect(
    getAllData,
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <>
      {loading ? (
        <State icon={faCircleNotch} iconClass="fa-spin text-primary" />
      ) : (
        <div className="row-cards row-cards-graph">
          <div className="col-dash-left">
            <div className="wrap-graph-box">
              <StakeGraph />
            </div>
          </div>
          <div className="col-dash-right text-center">
            <div className="wrap-stakes-box">
              <div className="text-center my-stake">
                <h3>My Stake</h3>
                <p>Welcome to our delegation platform.</p>
                <p>Currently, you don't have any EGLD staked.</p>
              </div>
              <div className={userActiveStake === String(0) ? "row-delegate mt-25-important" : "row-delegate"}>
                <div className="col-delegate">
                  <div className="box-delegate">
                      <div>
                          {/* <p>Active Delegation</p> */}
                          <p className="egld-delegate">
                              {userActiveStake} {egldLabel}
                          </p>
                      </div>
                      {/*<p className="claimable-delegate">~$0</p>*/}
                    <p className="title-delegate">
                      <img src={lockIcon} alt="Lock Icon" /> My stake
                    </p>
                    {userActiveStake !== String(0) && (
                      <div className="btn-wrap-delegate">
                        <div className="inverted-delegate">
                          {displayUndelegate && (
                            <UndelegateAction
                              balance={userActiveNominatedStake}
                            />
                          )}
                        </div>

                        <DelegateAction />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-delegate">
                  <div className="box-delegate">
                    <div>
                      <p className="egld-delegate">
                        {claimableRewards} {egldLabel}
                      </p>
                      {/*<p className="claimable-delegate">~$0</p>*/}
                      <p className="title-delegate">
                        <img src={trophyIcon} alt="Trophy Icon" />
                        Claimable rewards
                      </p>
                    </div>
                    {displayRewards ? <ClaimRewardsAction /> : null}
                  </div>
                </div>
                  {userActiveStake === String(0) ? (
                      <State
                          title=""
                          description=""
                          action={<DelegateAction />}
                          extraClass="pb-0-important"
                      />
                  ) : ('')}
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyDelegation;
