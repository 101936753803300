import React from "react";
import { Redirect } from "react-router-dom";
import { faBan, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import State from "components/State";
import { useContext } from "context";
import WalletLogin from "./Login/Wallet";
import WalletConnectLogin from "./Login/WalletConnect";
import closeLogin from "assets/images/close-login.svg";
import ledgerIcon from "assets/images/ledger-icon.png";

const Home = () => {
  const { loading, error, loggedIn, egldLabel } = useContext();

  const ref = React.useRef(null);

  return (
    <div ref={ref} className="home d-flex flex-fill align-items-center">
      {error ? (
        <State
          icon={faBan}
          iconClass="text-primary"
          title="Something went wrong"
          description="If the problem persists please contact support."
        />
      ) : loggedIn ? (
        <Redirect to="/dashboard" />
      ) : loading ? (
        <State icon={faCircleNotch} iconClass="fa-spin text-primary" />
      ) : (
        <div className="container">
          {/* style={{ backgroundImage: `url(${loginBackground})` }} */}
          <div className="login-container login-bg text-center">
            {/* <Logo className="logo img-fluid" /> */}

            <div className="close-login">
              <a href="https://www.cslabs.io/">
                <img src={closeLogin} alt="Close" />
              </a>
            </div>
            <h1 className="">Connect your wallet</h1>

            <p className="login-text">Please, select your login method.</p>

            <div className="login-btns">
              <WalletConnectLogin />
              <WalletLogin />
              <a
                href={process.env.PUBLIC_URL + "/ledger"}
                className="btn btn-primary px-sm-spacer mx-1 mx-sm-3"
              >
                <span className="icon-title">Ledger</span>
                <img src={ledgerIcon} alt="Ledger Icon" />
                <span className="icon-subtitle">Hardware</span>
              </a>
            </div>
            <div className="create-wallet">
              <p>
                or
                <a href="https://wallet.multiversx.com/create">
                  Create a Wallet
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
