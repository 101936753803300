import SVG from "react-inlinesvg";
import { StatCardType } from "helpers/types";
import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const StatCard = ({
  title = "",
  value = "0",
  valueUnit = "",
  color = "",
  svg = "",
  percentage = "",
  tooltipText = "",
  children = null,
  image = null,
}: StatCardType) => {
  return (
    <div className="col-card">
      <div className="statcard text-white bg-white">
        <div>
          <p className="stat-value">
            {value} {valueUnit}
          </p>
          <p className="">


            <img src={image} alt="Icon" />

            {title}
          </p>

          <div className=" text-center mt-1 mb-2 dd-children">
            <div>{children}</div>
          </div>
        </div>
      </div>

      {/* <small className="">
        {percentage}
        {tooltipText !== "" && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                {tooltipText}
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="text-white ml-1" />
          </OverlayTrigger>
        )}
      </small> */}
    </div>
  );
};

export default StatCard;
