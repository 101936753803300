import Denominate from "components/Denominate";
import React from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useContext, useDispatch } from "context";
import { useState } from "react";
import facebookIcon from "assets/images/facebook-icon.svg";
import twitterIcon from "assets/images/twitter-icon.svg";
import telegramIcon from "assets/images/telegram-icon.svg";
import logo from "assets/images/cslabs-logo.svg";
import exitWallet from "assets/images/exit-wallet.svg";
import walletIcon from "assets/images/wallet-menu-icon.svg";

const Navbar = () => {
  const { loggedIn, dapp, address, account, delegationContract } = useContext();
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  let chainNav = document.querySelector("chainnav");

  const logOut = () => {
    dispatch({ type: "logout", provider: dapp.provider });
  };

  function toggleShow() {
    setShow(!show);
  }

  return (
    <div className="navbar menu-bg">
      {/* <div className="container">
        <img width="227" className="img-fluid" src={logo} alt="" />
        {loggedIn && (
          <div className="text-right">
            <small className="d-none d-lg-inline text-muted mr-2">Balance: </small>
            <small className="text-truncate mr-2">
            <Denominate value={account.balance.toString()} />
            *</small>

            <div className="row">
              <div className="col-lg-10 d-none d-lg-block">
                <p className="text-right p-bold p-dark-blue">Wallet: </p>
                <p className="text-right p-dark-blue">{address}</p>
              </div>
              <div className="col-lg-2 d-flex align-items-center justify-content-center">
                <a href="/#" onClick={logOut} className=" btn-invert-white ">
                  Logout
                </a>
              </div>
            </div>
          </div>
        )}
      </div> */}
      <div
        id="specialmenu"
        className={
          show
            ? "container menu-container nav-spacing special-menu"
            : "container menu-container nav-spacing"
        }
      >
        <nav className="navbar navbar-expand-md p-0 w-100">
          <a className="navbar-brand" href="http://cslabs.io/">
            <img className="img-fluid" src={logo} alt="Logo" />
          </a>

          <button
            className={
              show
                ? "menu navbar-toggler menu-toggle opened"
                : "menu navbar-toggler menu-toggle"
            }
            onClick={toggleShow}
          >
            <svg width="30" height="30" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li>
                <a href="https://www.cslabs.io/#about" className="nav-link">
                  About
                </a>
              </li>
              <li>
                <a
                  href="https://www.cslabs.io/#infrastructure"
                  className="nav-link"
                >
                  Infrastructure
                </a>
              </li>
              <li>
                <a
                  href="https://www.cslabs.io/#calculator"
                  className="nav-link"
                >
                  Rewards
                </a>
              </li>
              <li>
                <a href="https://www.cslabs.io/#contact" className="nav-link">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="contract-adress d-md-flex d-none">
            {loggedIn && (
              <div className="wallet-adress d-none d-lg-block">
                <p className="d-flex">
                  <img src={walletIcon} alt="Wallet Icon" />{" "}
                  <span>{delegationContract}</span>
                </p>
              </div>
            )}
            <a href="/#" onClick={logOut} className="exit-button">
              <img src={exitWallet} alt="Exit" />
            </a>
          </div>
        </nav>
      </div>

      <div
        className={
          show
            ? "chainnav navopen height100 overlay"
            : "chainnav overlay height0"
        }
      >
        <div className="overlay-content">
          <ul className="navbar-nav">
            <li>
              <a href="https://www.cslabs.io/#about" className="nav-link">
                About
              </a>
            </li>
            <li>
              <a
                href="https://www.cslabs.io/#infrastructure"
                className="nav-link"
              >
                Infrastructure
              </a>
            </li>
            <li>
              <a href="https://www.cslabs.io/#calculator" className="nav-link">
                Rewards
              </a>
            </li>
            <li>
              <a href="https://www.cslabs.io/#contact" className="nav-link">
                Contact
              </a>
            </li>
          </ul>
          <div className="text-center d-flex align-center justify-content-center icons-overlay">
            <a href="cslabs.io">
              <img className="icon-footer" src={twitterIcon} alt="Twitter" />
            </a>
            <a href="cslabs.io">
              <img className="icon-footer" src={telegramIcon} alt="Telegram" />
            </a>
            <a href="cslabs.io">
              <img className="icon-footer" src={facebookIcon} alt="Facebook" />
            </a>
          </div>
          <div className="contract-adress d-flex align-items-baseline justify-content-center">
            <div>
              {loggedIn && (
                <div className="wallet-adress">
                  <p className="d-flex">
                    <img src={walletIcon} alt="Wallet Icon" />{" "}
                    <span>{delegationContract}</span>
                  </p>
                </div>
              )}
              <a href="/#" onClick={logOut} className="exit-button">
                <img src={exitWallet} alt="Exit" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={show ? "menuspace d-block" : "menuspace"}></div>
    </div>
  );
};

export default Navbar;
