import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Address } from "@elrondnetwork/erdjs";
import { useContext, useDispatch } from "context";
import SetAgencyMetaDataModal from "./SetAgencyMetaDataModal";
import { getItem } from "storage/session";
import copyIcon from "assets/images/copy-icon.svg";
import shareIcon from "assets/images/share-icon.svg";
import settingsIcon from "assets/images/settings-icon.svg";

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    address,
    delegationContract,
    contractOverview,
    ledgerAccount,
    walletConnectAccount,
    account
  } = useContext();

  const isOwner = () => {
    let loginAddress = new Address(address).hex();
    return loginAddress.localeCompare(contractOverview.ownerAddress) === 0;
  };

  const isOwnerPath = () => {
    let currentURL = window.location.pathname;
    return currentURL.includes("owner") === true;
  };

  const fetchLedger = () => {
    if (getItem("ledgerLogin") && !ledgerAccount) {
      const ledgerLogin = getItem("ledgerLogin");
      dispatch({
        type: "setLedgerAccount",
        ledgerAccount: {
          index: ledgerLogin.index,
          address: address,
        },
      });
    }
  };

  const fetchWalletConnect = () => {
    if (getItem("walletConnectLogin") && !walletConnectAccount) {
      dispatch({
        type: "setWalletConnectAccount",
        walletConnectAccount: address,
      });
    }
  };
  useEffect(fetchLedger, /* eslint-disable react-hooks/exhaustive-deps */ []);
  useEffect(
    fetchWalletConnect,
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <div className="row-cards dash-head">
      <div className="col-dash-left">
        <h1>Welcome back{isOwner() ? ", Admin" :  account.username !== '' ? ", " + account.username : "" }👋 </h1>
      </div>
      <div className="col-dash-right text-right">
        <div className="d-inline-block wrap-adres-head">
          <p className="d-sm-flex d-block position-relative">
            Contract address
            <span className="deleg-contract d-sm-inline-block d-none">
              {delegationContract}
            </span>
            <span className="d-inline-block icons-share-deleg">
              <img
                onClick={() =>
                  navigator.clipboard.writeText(delegationContract + "")
                }
                src={copyIcon}
                alt="Copy Icon"
              />
              <a
                href={
                  "https://explorer.multiversx.com/accounts/" +
                  delegationContract
                }
              >
                <img src={shareIcon} alt="Copy Icon" />
              </a>
            </span>
            <span className="deleg-contract d-block d-sm-none">
              {delegationContract}
            </span>
          </p>
        </div>

        {isOwner() && !isOwnerPath() ? (
          <Link to="/owner" className="head-btns">
            Admin Panel
            <img src={settingsIcon} alt="Copy Icon" />
          </Link>
        ) : null}
        {/* {pathname !== '/dashboard' ? ( */}
        {/* <Link to="/dashboard" className="head-btns">
          Dashboard
        </Link> */}
        {/* // ) : null} */}
        {/* // {isOwner() && isOwnerPath() ? <SetAgencyMetaDataModal /> : null} */}
      </div>
    </div>
  );
};

export default Header;
